import React from "react"
import Modal from "../components/modal"
import Button from "../components/button"
import Image from "../components/image"

class Testimonial extends React.Component {
  state = { show: false }

  showModal = () => {
    this.setState({ show: false })
  }

  hideModal = () => {
    this.setState({ show: false })
  }
  render() {
    const { clientimage, children, name, companyname } = this.props;
    return (
      <div className="container-full">
        <div className="testimonial-wrap">
          <div className="container">
            <div className="testimonial-project">
              <div className="client-text">
                <span className="client-quote">{children}</span>
                <div className="client-info-wrap a-flex">
                  <img className="client-circle a-flex__item" src={clientimage}/>
                  <p className="client-name a-flex__item">
                    <strong>{name} - </strong>
                    <i>{companyname}</i>
                    <br />
                  </p>
                </div>
                <Modal
                  show={this.state.show}
                  handleClose={this.hideModal}
                  client="casey"
                  clientInfo="Now you can access those props. But! You do not use the keyword 'this'. Since iakat’s not a class you don’t have 'asdf' to reference the component itself."
                  clientSiteImg={<Image />}
                />
                  {false && <Button type="ghost" onClick={this.showModal}>
                    See Project
                  </Button>}
                </div>
              </div>
            </div>
          </div>
        </div>
    )
  }
}
export default Testimonial;
