import React, { Fragment } from "react"
import classnames from "classnames"

const SideBySide = props => {
  const { className, subheading, children, img, alt, heading, inverse } = props
  return (
    <Fragment>
      <div className={classnames(className, "a-flex flex-column flex-md-row our-process p-2")}>
        <div className="a-flex__item mr-5">
          {!inverse ? (
            <div className="our-process-info">
              <h2>{heading}</h2>
              {subheading ? <h3>{subheading}</h3> : null}
              <p className="a-margin-top--15">{children}</p>
            </div>
          ) : (
            <div style={{ margin: `auto`, maxWidth: `600px` }}>
              <img src={img} alt={alt} />
            </div>
          )}
        </div>
        <div className="a-flex__item mr-5">
          {!inverse ? (
            <div style={{ margin: `auto`, maxWidth: `400px` }}>
              <img src={img} alt={alt} />
            </div>
          ) : (
            <div className="our-process-info">
              <h2>{heading}</h2>
              {subheading ? <h3>{subheading}</h3> : null}
              <p className="a-margin-top--15">{children}</p>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  )
}

export default SideBySide
