import React, { Component, Fragment, useContext } from "react";
import Layout from "./components/layout";
import Testimonial from "./components/Testimonial";
import MainLogo from "./components/main-logo";
import Button from "./components/button";
import Divider from "./components/hr";
import Header from "./components/header";
import Scene from "./images/computer.webp";
import SideBySide from "./components/sidebyside";
import CustomerLogos from "./components/customerlogos";
import Blocks from "./components/blocks";
import Single from "./images/single-person.svg";

const IndexPage = () => {
  return (
    <Layout>
      <Header/>
      <div className="container-full">
        <svg
          className="wave-top"
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 36"
        >
          <path
            className="cls-1"
            d="M0,17S91,35,227,29c119.88-5.29,150-7,273-17,66-5.37,135-7,264-7,159,0,236,19,236,19V0H0Z"
          />
        </svg>
        <div className="main-cta pb-5">
          <div className="container position-relative">
            <SideBySide
              inverse
              className="p-2"
              heading="We're here to help your brand grow..."
              img={Scene}
              alt="Alvarez Branding Company"
            >
              Alvarez Branding Company is an all inclusive web design and
              development company specializing in personal and professional
              websites, logo design and finding creative ways to express
              ideas that will engage your clients. We help growing
              businesses like yours build a brand and create an online
              presence that reflects their personal style and uniqueness.
              <br />
              <br />
              Have a project you want to talk about with us?  Click below to get in touch.
              <br/>
              <Button
                className="mt-3"
                secondary
                onClick={() => window.location = "https://alvarezbranding.hbportal.co/public/64f929dfc761c400e5621743"}>
                Schedule a call
              </Button>
            </SideBySide>
          </div>
        </div>
        <img className="corner-plants-top-right" src="Corner Plants.svg" alt=""/>
      </div>
      <CustomerLogos className="mb-5 pb-5 mt-5 pt-5"/>
      <img className="corner-plants-left" src="Plant Left.svg" alt=""/>

      <Testimonial
        clientimage="cristina-client.jpg"
        name="Cristina Hoyt"
        companyname="CristinaHoyt.com"
        //clientUrl="http://www.nourishmbml.com"
      >
        "Since I had my website updated my sales have gone up 300% with an
        easy to navigate website and integrated SEO. I’ve started to be able
        to attract clients I didn’t know were available."
      </Testimonial>
    </Layout>
      );
      }
      export default IndexPage
