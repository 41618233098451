import React from "react"
import classnames from "classnames"

class Divider extends React.Component {
  render() {
    const { dark, light, className } = this.props
    return (
      <div className={classnames(className, "divider", { dark: dark }, { light: light })}>
        <div className="line"></div>
        <div className="small-line"></div>
        <div className="dot"></div>
      </div>
    )
  }
}

export default Divider
