import React from "react"
import classnames from "classnames"

class Button extends React.Component {
  render() {
    const {
      className,
      children,
      link,
      type,
      small,
      ghost,
      onClick,
    } = this.props
    const buttonBasic = "a-button"
    const buttonClass = classnames(
      buttonBasic,
      { "a-button--primary": !type },
      { [`a-button--${type}`]: type },
      { [`a-button--small`]: small },
      { [`a-button--ghost`]: ghost },
      className
    )
    return (
      <a href={link} onClick={onClick} className={buttonClass} type={type}>
        {children}
      </a>
    )
  }
}

export default Button
