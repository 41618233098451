import React, { Component } from "react"
import ARV from "../images/auburn-road.svg";
import HT from "../images/happy-tails.svg";
import Haddon from "../images/haddon.svg";
import PG from "../images/papergreats.svg";
import NTS from "../images/night-to-shine.svg";
import M2 from "../images/m2.svg";
import WHE from "../images/whole-hearted-eating.svg";
import PB from "../images/pablo-batista.svg";

class CustomerLogos extends Component {
  render() {
    const list = [ARV, HT, Haddon, PG, NTS, M2, WHE, PB]
    list.push(...list)
    const { className } = this.props

    return (
      <div className={className}>
        <h2 className="title">Brands that trust us</h2>
        <div className="customer-logo-wrap col" listLength={list.length}>
          <div className="row mover-1 flex-nowrap">
            {list.map((item, i) => {
              return (
                <div key={i} className="col-2 customer-logo container">
                  <img src={item}/>
                </div>
                )
              })}
          </div>
        </div>
      </div>
    )
  }
}
export default CustomerLogos
